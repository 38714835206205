import React from 'react'

export default React.memo(function CategoriesInput({
  categories, categoriesOpInclusionMap, setcategoriesOpInclusionMap
}) {
  return <div className='categories-radios-wrap'>
    {categories && categories.map((category) => {
      const categoryForId = category.replace(' ', '-');
      return (
        <div key={category} className='d-flex flex-column'>
          <p key={category} value={category}>{category === 'null' ? '(Uncategorized)' : category}</p>
          <div className="align-items-center d-flex">
            <input
              value={categoriesOpInclusionMap[category] ? 'true' : false}
              className="form-radio"
              type="radio"
              id={`category-${categoryForId}-included`}
              onChange={() => {
                categoriesOpInclusionMap[category] = true;
                setcategoriesOpInclusionMap(categoriesOpInclusionMap);
              }}
              checked={categoriesOpInclusionMap[category] ? 'checked' : false}
            />
            <label className="mb-0 mr-4 font-15 pl-2" htmlFor={`category-${categoryForId}-included`}>Yes</label>
            <input
              value={!categoriesOpInclusionMap[category] ? 'true' : false}
              className="form-radio"
              type="radio"
              id={`category-${categoryForId}-not-included`}
              onChange={() => {
                categoriesOpInclusionMap[category] = false;
                setcategoriesOpInclusionMap(categoriesOpInclusionMap);
              }}
              checked={!categoriesOpInclusionMap[category] ? 'checked' : false}
            />
            <label className="mb-0 font-15 pl-2" htmlFor={`category-${categoryForId}-not-included`}>No</label>
          </div>
        </div>
      )
    })}
  </div>
});
