import axios from './axios'

export const getEditors = function (summaryId) {
  return axios.get(`/summaries/${summaryId}/editors.json`)
}

export const createEditor = function (summaryId, user_email) {
  return axios.post(`/summaries/${summaryId}/editors.json`, { user_email })
}

export const updateEditor = function (summaryId, editorId, user_email) {
  return axios.put(`/summaries/${summaryId}/editors/${editorId}.json`, { user_email })
}

export const removeEditor = function (summaryId, editorId, destroy_invited) {
  return axios.delete(`/summaries/${summaryId}/editors/${editorId}.json`, { params: { destroy_invited } })
}
