import React, { useRef, useCallback } from 'react'
import ReactDOM from 'react-dom';

import ConfirmTerminateModal from './ConfirmTerminateModal'

export default React.memo(function Footer({
  handleNext, handleBack, showNext, selectedCount, tabIndex, errorMessage
}) {
  const modalEl = useRef(null);

  const onCancel = useCallback((e) => {
    $(ReactDOM.findDOMNode(modalEl.current)).modal('show');
    e.preventDefault();
  }, [modalEl]);

  return (
    <>
      <footer className="align-items-center bg-light d-flex fixed-bottom footer">
        <div className='d-flex justify-content-between w-100'>
          {renderLeftBlock(tabIndex, handleBack, onCancel)}
          {showNext && renderRightBlock({ handleNext, selectedCount, tabIndex, errorMessage })}
        </div>
      </footer>
      <ConfirmTerminateModal ref={modalEl}/>
    </>
  );
})

function renderRightBlock({ handleNext, selectedCount, tabIndex, errorMessage }) {
  const saveAndExit = tabIndex === 4;

  return (
    <div className='d-flex justify-content-center align-items-center'>
      {tabIndex !== 2 && <p className='mb-0 mr-4 selected-rows-text'>{`${selectedCount} Rows Selected`}</p>}
      <div aria-label={errorMessage || undefined}
           data-balloon-pos="left"
           data-balloon-blunt>
        <button
          id='savebtn'
          className='btn btn-primary btn-round-edge'
          onClick={handleNext}
          disabled={errorMessage}>
          {saveAndExit ? 'Save & Exit' : 'Next'}
          {!saveAndExit && <img className='pl-2 pb-1' src='/images/next_icon.svg' />}
        </button>
      </div>
    </div>
  );
}

function renderLeftBlock(tabIndex, handleBack, onCancel) {
  return (
    <div className='d-flex justify-content-center align-items-center'>
      {tabIndex >= 2 &&
        <button className='btn btn-outline-primary btn-round-edge mr-4 btn-disabled-focus'
                onClick={handleBack}>
        <img className='pr-2 pb-1' src='/images/back_icon_bluish.svg' />
        Back
      </button>}
      <a className='light-link color-secondary' href='#' onClick={onCancel}>Cancel</a>
    </div>
  );
}
