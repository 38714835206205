import React, { useCallback } from 'react'
// const regex = new RegExp("^[-+]?[0-9]+([\.][0-9]{0,6})?");

export default React.memo(function NumberInput({ value, setValue, placeholder, positive }) {
  const changeHandler = useCallback((e) => {
    if (e.target.validity.valid || (!positive && e.target.value === '-')) {
      setValue(e.target.value)
    }
  }, [setValue, positive])

  return <input
    type="text"
    value={value}
    className="form-control string optional"
    pattern={`^${positive ? '' : '[-]?'}[0-9]+([\.][0-9]{0,6})?`}
    placeholder={placeholder}
    onChange={changeHandler}
  />
});
