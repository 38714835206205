import React, { useState, useEffect } from 'react'
import { parseCsvRows } from '../../actions'

import Row from './Row'
import TableHeader from './TableHeader'
import { useEditors } from './../../../../hooks/editorsHook'

export default function Show({
  extractedRows, rawRows, hideHeader, currentUser, editorsColorMap, editorMode, summaryId
}) {
  const [parsedRows, setParsedRows] = useState([]);
  let currentUserInt = currentUser;
  let editorsColorMapInt = editorsColorMap;

  useEffect(() => {
    let parsedRowsTemp = extractedRows;
    if (rawRows) parsedRowsTemp = parseCsvRows(rawRows);

    setParsedRows(parsedRowsTemp);
  }, [extractedRows, rawRows])

  const editorHookData = useEditors();
  const { setSummaryIdInternal } = editorHookData;

  if (editorMode && !editorsColorMap) {
    currentUserInt = editorHookData.currentUser;
    editorsColorMapInt = editorHookData.editorsColorMap;
  }

  useEffect(() => {
    setSummaryIdInternal(summaryId);
  }, [summaryId, setSummaryIdInternal]);

  if (editorMode && !editorsColorMapInt) return null;

  return (
    <div className='summary-show-wrap'>
      {!hideHeader && <div className='d-flex flex-column justify-content-center'>
        <h2 className='color-primary mb-3 mt-4 font-weight-normal'>Summary</h2>
      </div>}

      <div className='inner-content-wrap'>
        <div className='d-flex flex-column'>
          <TableHeader />
          {parsedRows.map((row, i) => {
            return (
              <Row
                category={row.category}
                editorMode={editorMode}
                key={i}
                includedCols={row.included_cols}
                id={row.id}
                data={row.data}
                approvedCRinRow={row.approvedCRinRow}
                editorsColorMap={editorsColorMapInt}
                changeRequests={row.change_requests}
                includedColsToBe={row.includedColsToBe}
                currentUser={currentUserInt}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
