import React, { useEffect } from 'react'

import { useRowCRs } from '../../../../hooks/rowHook'

import Cell from '../Select/Cell'

export default React.memo(function Row({
  includedCols, category, data, changeRequests, approvedCRinRow, editorMode, editorsColorMap, currentUser,
  includedColsToBe
}) {
  const rowsCRsData = useRowCRs();
  const {
    changeRequestsByColNumber,
    updateDataFromCRs
  } = rowsCRsData;

  useEffect(() => {
    updateDataFromCRs(changeRequests);
  }, [changeRequests, updateDataFromCRs]);

  // indexes which can be shown
  let indexes = includedColsToBe ? [...includedColsToBe] : [];
  if (editorMode) {
    indexes = indexes.concat(Object.keys(changeRequestsByColNumber).map((k) => (
      parseInt(k)
    )), 0).filter((i) => !approvedCRinRow || i === 0 || i === approvedCRinRow.proposed_cells[1]);
    indexes = [...new Set(indexes)]
  }

  if (indexes.length < 2) return null;

  let hasPendingChangeRequests = editorMode && changeRequests.length > (approvedCRinRow ? 1 : 0)

  return (
    <div className={
      `d-flex flex-row form-group  m-0 row-wrap ${hasPendingChangeRequests ? 'greyed' : ''}`
    }>
      <div className='data-wrap px-1'>
        {data.map((val, i) => {
          let changeRequest = changeRequestsByColNumber[i];
          return (
            editorMode
              ? changeRequest || indexes.includes(i)
                ? <Cell
                    key={i}
                    editorsColorMap={editorsColorMap}
                    rowhasChnageRequest={changeRequests && changeRequests.length > 0}
                    changeRequest={approvedCRinRow ? undefined : changeRequest}
                    index={i}
                    data={data}
                    dataLength={data.length}
                    includedCols={approvedCRinRow ? undefined : includedCols}
                    approvedCRinRow={approvedCRinRow}
                    currentUser={currentUser}
                    val={val}
                    disablePopup={true}
                    cellWidth={i === 0 ? '50' : `${50 / (indexes.length - 1)}`}
                  />
                : null
              : includedColsToBe.includes(i)
                  ? <div
                      className='align-items-center d-flex'
                      key={i}
                      style={{width: '50%'}}>{val}
                    </div>
                  : null
          );
        })}
        <div className='category-column align-items-center d-flex'>{category}</div>
      </div>
    </div>
  );
})
