import React, { useState } from 'react'

export default React.memo(function ShowAsDropDown({ onChange, viewerUrl, editorUrl, selected }) {
  const [urlChanged, setUrlChanged] = useState(false);

  return (
    <div className='align-items-center d-flex view-summary-as-dropdown-wrapper'>
      <p className='mb-0 mr-3 color-secondary'>View as</p>
      <button className="btn btn-dropdwon dropdown-toggle dark-text font-weight-normal btn-disabled-focus"
              type="button"
              id="viewAsDropDown"
              disabled={urlChanged}
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {selected}
      </button>
      <div className="dropdown-menu" aria-labelledby="viewAsDropDown">
        <div
          className={`dropdown-item cursor-pointer ${selected !== 'Editor' ? 'dark-text-opacity' : 'dark-text'}`}
          onClick={() => {
            if (selected === 'Editor') {
              return;
            } else if (editorUrl) {
              location.href = editorUrl
              setUrlChanged(true);
            } else {
              onChange('Editor')
            }
          }}>
          <h4 className='font-15 mb-1'>Editor</h4>
          <p className='font-14'>See how it will show up to an editor on this file.</p>
        </div>
        <div
          className={`dropdown-item cursor-pointer ${selected !== 'Viewer' ? 'dark-text-opacity' : 'dark-text'}`}
          onClick={() => {
            if (selected === 'Viewer') {
              return;
            } else if (viewerUrl) {
              location.href = viewerUrl
              setUrlChanged(true);
            } else {
              onChange('Viewer')
            }
          }}>
          <h4 className='font-15 mb-1'>Viewer</h4>
          <p className='font-14 mb-0'>See how it will show up to a viewer.</p>
        </div>
      </div>
    </div>
  );
})
