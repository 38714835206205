import React, { useState, useCallback } from 'react'

import Row from './Row'
import Show from '../Show'
import ShowAsDropDown from '../Show/ShowAsDropDown'
import TableHeader from './TableHeader'
import PageHeading from '../../PageHeading'

import { updateExtraRowData } from '../../actions'

export default React.memo(function SelectItems({
  extractedRows, setExtractedRows, categories, tabIndex, editing, currentUser,
  needApprovals, editorsColorMap
}) {
  const [showingSummaryAs, setShowingSummaryAs] = useState('Editor');
  const onRowChange = useCallback((changeRequests, nowIncludedCols, category, rowIndex, isShiftKey) => {
    setExtractedRows((rows) => {
      // const wasIncluded = rows[rowIndex].included_cols;

      rows[rowIndex].changed = true;
      rows[rowIndex].category = category;
      rows[rowIndex].change_requests = changeRequests;
      rows[rowIndex].included_cols = nowIncludedCols;

      rows[rowIndex] = updateExtraRowData(rows[rowIndex]);

      // TODO: Fix logic for shift key multi-select(edge cases introduced after cell selection logic)
      // if (!isEqual(wasIncluded, nowIncludedCols) && isShiftKey) {
      //   let i = rowIndex - 1;
      //   while (i > 0 && !isEqual(rows[i].included_cols, nowIncludedCols)) {
      //     rows[i].included_cols = nowIncludedCols;
      //     i--;
      //   }
      // }
      return [...rows]
    });
  }, [setExtractedRows]);

  const removeChangeRequest = useCallback((rowIndex, crId) => {
    setExtractedRows((rows) => {
      rows[rowIndex].changed = true;
      rows[rowIndex].change_requests = rows[rowIndex].change_requests.filter((cr) => cr.id !== crId);
      if (crId) {
        rows[rowIndex].change_request_ids_to_remove = rows[rowIndex].change_request_ids_to_remove || []
        rows[rowIndex].change_request_ids_to_remove.push(crId);
      }
      rows[rowIndex] = updateExtraRowData(rows[rowIndex]);

      return [...rows]
    });
  }, [setExtractedRows])

  return (
    tabIndex === 1 ? <div>
      <PageHeading
        heading={`${editing ? 'Edit' : 'Select'} Items`}
        helpText='Choose data that you want to be included in your final report.'
      />

      <div className='inner-content-wrap mb-5'>
        <div className='d-flex flex-column'>
          <TableHeader />
          {extractedRows.map((row, i) => {
            return (
              <Row
                includedCols={row.included_cols}
                category={row.category}
                categories={categories}
                key={i}
                index={i}
                id={row.id}
                data={row.data}
                editorsColorMap={editorsColorMap}
                changeRequests={row.change_requests}
                approvedCRinRow={row.approvedCRinRow}
                currentUser={currentUser}
                needApprovals={needApprovals}
                removeChangeRequest={removeChangeRequest}
                onRowChange={onRowChange}/>
            );
          })}
        </div>
      </div>
    </div>
    : <div>
      <PageHeading
        heading='Review Line Items'
        helpText='Review selected line items data before further processing.'
      >
        <div className='mb-4'>
          <ShowAsDropDown onChange={setShowingSummaryAs} selected={showingSummaryAs} />
        </div>
      </PageHeading>

      <Show
        editorsColorMap={editorsColorMap}
        currentUser={currentUser}
        extractedRows={extractedRows}
        categories={categories}
        editorMode={showingSummaryAs === 'Editor'}
        hideHeader={true}/>
    </div>
  );
})
