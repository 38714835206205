import consumer from "./consumer"

export const suscribeToNewNotifications = (callback) => {
  let subscriptions = consumer.subscriptions.findAll(JSON.stringify({
    channel: "NotificationsChannel"
  }));

  if (subscriptions.length !== 0) {
    subscriptions[0].received = callback;
    return;
  }

  consumer.subscriptions.create({ channel: "NotificationsChannel" }, {
    connected() {
      console.log('notification channel connected')
    },

    disconnected() {
      console.log('notification channel dis-connected')
    },

    unsubscribed() {
      console.log('notification channel unsubscribed')
    },

    received(data) {
      callback(data);
    }
  })
}
