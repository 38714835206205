import React, { useEffect, useCallback } from 'react'

import CategorySelector from './CategorySelector'
import Cell from './Cell'

import { useRowCRs } from '../../../../hooks/rowHook'

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.includedCols    === nextProps.includedCols &&
    prevProps.category        === nextProps.category &&
    prevProps.index           === nextProps.index &&
    prevProps.changeRequests  === nextProps.changeRequests &&
    prevProps.onRowChange     === nextProps.onRowChange &&
    prevProps.currentUser     === nextProps.currentUser &&
    prevProps.needApprovals   === nextProps.needApprovals &&
    prevProps.editorsColorMap === nextProps.editorsColorMap
  );
}

export default React.memo(function Row({
  includedCols, category, categories, index, id, data, changeRequests, approvedCRinRow,
  onRowChange, removeChangeRequest, currentUser, needApprovals, editorsColorMap
}) {
  const rowsCRsData = useRowCRs();
  const {
    changeRequestsByColNumber,
    changeRequestsByUserId,
    updateDataFromCRs
  } = rowsCRsData;

  // Build and save mappings
  useEffect(() => {
    updateDataFromCRs(changeRequests);
  }, [changeRequests, updateDataFromCRs]);

  const onCategoryChange = useCallback((e) => {
    onRowChange(changeRequests, includedCols, e.target.value, index, e.shiftKey);
  }, [onRowChange, changeRequests, index, includedCols]);

  const onCellClick = useCallback((e, i) => {
    if (i === 0 && !includedCols) return;

    let changeRequestOnCol  = changeRequestsByColNumber[i];
    let changeRequestByUser = changeRequestsByUserId[currentUser.id];
    if (!changeRequestOnCol) {
      // Dis-allow multiple change requests by same user on same row
      if (changeRequestByUser || approvedCRinRow) return;

      let proposedCols = [0, i];

      if (needApprovals) {
        let proposalCategory = getProposalCategory(includedCols, i);
        if (proposalCategory) {
          let newChangeRequests = [...changeRequests];

          newChangeRequests.push({
            category: proposalCategory,
            editor: { id: currentUser.editor_id, user: currentUser },
            old_cells: includedCols,
            proposed_cells: proposedCols
          })
          onRowChange(newChangeRequests, includedCols, category, index, e.shiftKey);
        }
      } else {
        // User does not need any approvals
        if (includedCols && includedCols.includes(i)) proposedCols = null;
        onRowChange(changeRequests, proposedCols, category, index, e.shiftKey);
      }
    }
  }, [
    onRowChange, category, index, includedCols,
    changeRequestsByColNumber, changeRequests, currentUser,
    changeRequestsByUserId, needApprovals, approvedCRinRow
  ]);

  const updateChangeRequest = useCallback((updatedChangeRequest, cellNumber) => {
    if (updatedChangeRequest.removed) {
      removeChangeRequest(index, updatedChangeRequest.id);
    } else {
      if (changeRequestsByUserId[currentUser.id]) {
        alert('Please remove your suggestion from the row before approving a change on the same row');
        return;
      }

      updatedChangeRequest.changed = true
      changeRequestsByColNumber[cellNumber] = updatedChangeRequest
      onRowChange(
        [...Object.values(changeRequestsByColNumber)],
        includedCols, category, index, false
      );
    }
  }, [onRowChange, category, index, changeRequestsByColNumber, includedCols, removeChangeRequest, changeRequestsByUserId, currentUser]);

  return (
    <div className='row-wrap-wihtout-checkbox d-flex flex-row form-group m-0 row-wrap'>
      <div className='data-wrap'>
        {data.map((val, i) => {
          let changeRequest = changeRequestsByColNumber[i];
          return (
            <Cell
              key={i}
              editorsColorMap={editorsColorMap}
              rowhasChnageRequest={changeRequests && changeRequests.length > 0}
              changeRequest={changeRequest}
              updateChangeRequest={updateChangeRequest}
              index={i}
              data={data}
              dataLength={data.length}
              includedCols={approvedCRinRow ? undefined : includedCols}
              approvedCRinRow={approvedCRinRow}
              currentUser={currentUser}
              onClick={onCellClick}
              val={val}
            />
          );
        })}
        <div className='align-items-center d-flex'>
          <CategorySelector onChange={onCategoryChange}
                            categories={categories}
                            category={category}/>
        </div>
      </div>
    </div>
  );
}, arePropsEqual)

function getProposalCategory(includedCols, i) {
  if (includedCols && includedCols.length > 0) {
    if (includedCols.includes(i)) {
      return 'remove'
    } else {
      return 'edit'
    }
  } else {
    return 'add'
  }
}
