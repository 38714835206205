import React, { useState, useEffect } from 'react'

import {
  primaryUserColor, secondaryUserColor, primaryUserColorLight, secondaryUserColorLight
} from './../../../Avatar/helper'
import ChangeDetail from './Popovers/ChangeDetail'

export default React.memo(function Cell({
  includedCols, rowhasChnageRequest,
  currentUser, changeRequest, onClick, disablePopup,
  index, dataLength, val, data, updateChangeRequest, approvedCRinRow, editorsColorMap,
  cellWidth
}) {
  const [popover, setPopover] = useState(false);
  const [hasApprovedCR, setHasApprovedCR] = useState(false);

  useEffect(() => {
    setHasApprovedCR(changeRequest && !!changeRequest.approved);
  }, [changeRequest]);

  let cssClasses = ''
  let userColor = ''
  let userColorLight = ''

  if (changeRequest) {
    if (editorsColorMap[changeRequest.editor.id]) {
      userColor = primaryUserColor
      userColorLight = primaryUserColorLight
    } else {
      userColor = secondaryUserColor
      userColorLight = secondaryUserColorLight
    }

    if (hasApprovedCR) {
      cssClasses += 'approved'
    } else if (changeRequest.editor.user.id === (currentUser && currentUser.id)) {
      cssClasses += 'change-request-self'
    } else {
      cssClasses += 'change-request-other'
    }

    if (changeRequest.category === 'remove') cssClasses += ' text-decoration-line-through'
  } else {
    // TODO: Adjust css class for 1st cell/column when there is are change request/s for the row
    let removalOfRowIsNotApproved = !approvedCRinRow || approvedCRinRow.category !== 'remove'
    let cellInIncludedCols = includedCols && (includedCols.includes(index) || index === 0)

    let cellIncluded = (cellInIncludedCols ||
                       (rowhasChnageRequest && index === 0) && removalOfRowIsNotApproved)
    cssClasses += cellIncluded ? ' selected' : ''
  }

  // console.log('cell render')

  let styles = {
    width: `${cellWidth ? cellWidth : (100 / dataLength)}%`,
    backgroundColor: !hasApprovedCR && userColorLight
  }

  if (popover) styles['boxShadow'] = `0px 0px 6px ${userColorLight}`

  return (
    <div
      className={`${cssClasses} indicator-icon-wrap`}
      onClick={(e) => {
        changeRequest ? setPopover(!popover) : (onClick && onClick(e, index))
      }}
      style={styles}>
      <div className='align-items-center d-flex w-h-100'>
        <div>{val}</div>
      </div>
      {changeRequest
        ? <div className="left-indicator" style={{backgroundColor: userColor}}>
            {changeRequest.editor.user.name[0]}
          </div>
        : undefined}

      {!disablePopup && changeRequest && popover && <ChangeDetail
        setShow={setPopover}
        data={data}
        alignRight={dataLength > 5 && index === (dataLength - 1)}
        editorsColorMap={editorsColorMap}
        updateChangeRequest={(newChangeRequest) => updateChangeRequest(newChangeRequest, index)}
        changeRequest={changeRequest}
        currentUserId={currentUser.id}
      />}
    </div>
  );
})
