import axios from './axios'

export const getNotifications = function (page) {
  return axios.get('/notification.json', { params: { page } })
}

export const readNotification = function (notificationId) {
  return axios.put(`/notification/${notificationId}/read.json`)
}

export const readAllNotifications = function () {
  return axios.put(`/notification/read_all.json`)
}
