import React from 'react'
import Avatar from './../Avatar'

export default React.memo(function Notifications({
  source_user_name, source_summary_title, content, source_summary_id,
  read, category, id,
  display_time, onNotificationClick
}) {
  return (
    <div className={`align-items-center d-flex flex-row notification-container ${read ? 'read' : ''}`}
         onClick={() => onNotificationClick(id, source_summary_id)}>
      {category === 'invite'
        ? <img src='/images/add_user_icon.png' />
        : <Avatar value={source_user_name[0]}
                  size="28"
                  round="20px"
                  textSizeRatio={2}
                  color='#EAF0F6'
                  fgColor='#44537E' />
      }
      <p className='notification-content px-2 m-0 color-secondary'>
        <b>{source_user_name}</b> {content} “<b>{source_summary_title}</b>”
      </p>
      <div className='notification-time'>
        {display_time}
      </div>
    </div>
  );
})
