import { useState, useCallback } from 'react'

export const useRowCRs = () => {
  const [changeRequestsByColNumber, setChangeRequestsByColNumber] = useState({});
  const [changeRequestsByUserId, setChangeRequestsByUserId] = useState({});

  // Build and save mappings
  const updateDataFromCRs = useCallback((changeRequests) => {
    changeRequests && setChangeRequestsByColNumber(changeRequests.reduce((map, changeRequest) => {
      if (changeRequest.category === 'edit' || changeRequest.category === 'add') {
        map[changeRequest.proposed_cells[1]] = changeRequest;
      } else {
        map[changeRequest.old_cells[1]] = changeRequest;
      }
      return map;
    }, {}));

    changeRequests && setChangeRequestsByUserId(changeRequests.reduce((map, changeRequest) => {
      map[changeRequest.editor.user.id] = changeRequest;
      return map;
    }, {}));
  }, []);

  return {
    changeRequestsByColNumber,
    changeRequestsByUserId,
    updateDataFromCRs
  }
}
