import React, { useRef, useEffect, useState, useCallback } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { formatDate } from '../../../../../helpers/timehelper'

import InfoData from './InfoData'
import Avatar from './../../../../Avatar'

export default React.memo(function ChangeDetail({
  setShow, changeRequest, currentUserId, updateChangeRequest, data, editorsColorMap, alignRight
}) {
  const ref = useRef();
  const [timeString, setTimeString] = useState('')

  useEffect(() => {
    let date = changeRequest.created_at ? (new Date(changeRequest.created_at)) : (new Date())
    setTimeString(formatDate(date));
  }, [changeRequest]);

  useOnclickOutside(ref, () => {
    setTimeout(() => setShow(false), 100);
  });

  const updateChangeRequestInter = useCallback((updates) => {
    updateChangeRequest({...changeRequest, ...updates});
  }, [changeRequest, updateChangeRequest]);

  const defferedText = <p className='flex-1 mb-0 mr-2 orange-text no-wrap'>Deferred</p>

  const renderActions = () => {
    return (
      <div className='no-wrap flex-1'>
        {changeRequest.editor.user.id === currentUserId
          ? <div className='align-items-center d-flex justify-content-end'>
              {changeRequest.deferred_at && defferedText}
              <button
                className='btn btn-outline-primary btn-round-edge font-weight-normal'
                onClick={() => updateChangeRequestInter({removed: true})}>
                Remove
              </button>
            </div>
          : <div className='align-items-center d-flex justify-content-end'>
              {!changeRequest.deferred_at
                ? <div
                    className='mr-2 cursor-pointer'
                    onClick={() => updateChangeRequestInter({deferred_at: true, approved: false})}>
                    Defer For now
                  </div>
                : defferedText
              }
              {!changeRequest.approved && <button
                  className='btn btn-outline-primary btn-round-edge font-weight-normal'
                  onClick={() => updateChangeRequestInter({deferred_at: undefined, approved: true})}>
                  Approve
                </button>}
            </div>
        }
      </div>
    );
  }

  // Fix out of window for long rows
  let styles = {}
  if (alignRight) styles['right'] = 0

  return (
    <div ref={ref} className='change-popover' style={styles}>
      <div className='d-flex justify-content-between mb-1'>
        <div className='d-flex align-items-center'>
          <Avatar
            primaryColor={editorsColorMap[changeRequest.editor.id]}
            className='mr-6px'
            value={changeRequest.editor.user.name[0]}
            size="28"
            textSizeRatio={2}
            round="20px" />
          <div className='d-flex-flex-column mr-6px'>
            <p className='dark-text font-weight-light-bold font-14 m-0'>{changeRequest.editor.user.name}</p>
            <p className='m-0'>{timeString}</p>
          </div>
        </div>
        {renderActions()}
      </div>

      <div>
        <InfoData
          type={changeRequest.category}
          previousCellData={changeRequest.old_cells ? data[changeRequest.old_cells[1]] : undefined}
          proposedCellData={changeRequest.proposed_cells ? data[changeRequest.proposed_cells[1]] : undefined}
        />
      </div>
    </div>
  );
})
