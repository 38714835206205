import React, { useState, useEffect } from 'react'
import Avatar from './../Avatar'

export default React.memo(function NavBar({
  tabIndex, summaryId, summaryTitle, setSummaryTitle, uploadStarted, editing,
  editorsColorMap, summaryEditors, currentUserId
}) {
  const [width, setWidth] = useState(0);
  const [message, setMessage] = useState('You can always name or rename this summary from here.');

  useEffect(() => {
    if (tabIndex !== 0 && message) {
      let messageTimer = setTimeout(() => {
        setMessage(undefined);
      }, 4000);

      return () => {
        clearTimeout(messageTimer);
      }
    }
  }, [tabIndex, message, setMessage]);

  useEffect(() => {
    if(summaryTitle) setInputWidth(summaryTitle, setWidth);
  }, [summaryTitle]);

  return (
    <div className='navbar-main fixed-top'>
      <nav className='navbar navbar-expand-lg navbar-light text-white'>
        {tabIndex === 0
          ? <div className='position-relative align-items-center d-flex flex-1 justify-content-center mx-4'>
              {!(summaryId || uploadStarted) && renderCancel()}

              <b className='align-items-center d-flex justify-content-center w-100 title'>
                Start By Uploading A New File
              </b>
            </div>
          : <>
              <div className='title-input-wrap d-flex'
                   aria-label={message}
                   data-balloon-pos='down'
                   data-balloon-length='fit'
                   data-balloon-visible
                   onClick={() => setMessage(undefined)}>
                <input className='bg-transparent border-0 px-2'
                       style={{ width: `${width}px`, minWidth: '150px', maxWidth: '300px'}}
                       value={summaryTitle || ''}
                       onChange={(e) => setSummaryTitle(e.target.value) }
                       type='text'
                       placeholder='Draft Summary' />
                <img src='/images/pencil_icon_2.svg' />
              </div>
              <div className='pl-3 editors-avatars-wrapper'>
                {summaryEditors && summaryEditors.map((editor, index) => {
                  return (
                    <React.Fragment key={index}>
                      {editor.user &&
                        <Avatar
                          primaryColor={editorsColorMap[editor.id]}
                          className={`${editor.user.id === currentUserId && 'sb-avatar-highlighted'}`}
                          value={editor.user.name[0]} size="40" round="20px" />}
                    </React.Fragment>
                  );
                })}
              </div>
              <a data-toggle="modal"
                 className="btn btn-round-edge font-weight-normal btn-disabled-focus background-secondary-action-color"
                 href="#shareSummaryModal">
                Share
              </a>

              {renderNavs(tabIndex, editing)}
          </>
        }
      </nav>
    </div>
  );
})

function renderCancel() {
  return (
    <a href='/summaries' className='btn btn-info btn-round-edge cancel-btn position-absolute'>
      Cancel
    </a>
  )
}

function renderNavs(tabIndex, editing) {
  return (
    <>
      <button className='navbar-toggler'
          aria-controls='navbarNavDropdown'
          aria-expanded='false'
          aria-label='Toggle navigation'
          data-target='#navbarNavDropdown'
          data-toggle='collapse'
          type='button'>
        <span className='navbar-toggler-icon' />
      </button>

      <div className='collapse navbar-collapse' id='navbarNavDropdown'>
        <ul className='navbar-nav w-100'>
          <li className='nav-item dropdown ml-auto d-flex'>
            <a className={`nav-link-c ${tabIndex == 1 ? 'active' : ''}`}>
              <b>{`${editing ? 'Edit' : 'Select'} Items`}</b>
              <img src='/images/next_icon.svg' />
            </a>
            <a className={`nav-link-c ${tabIndex == 2 ? 'active' : ''}`}>
              <b>Review Items</b>
              <img src='/images/next_icon.svg' />
            </a>
            <a className={`nav-link-c ${tabIndex == 3 ? 'active' : ''}`}>
              <b>Additional Info</b>
              <img src='/images/next_icon.svg' />
            </a>
            <a className={`nav-link-c ${tabIndex == 4 ? 'active' : ''}`}>
              <b>Review & Save</b>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

function setInputWidth(text_a, setWidth) {
  let text = document.createElement('span');
  document.body.appendChild(text);
  text.style.font       = 'times new roman';
  text.style.fontSize   = 23 + 'px';
  text.style.height     = 'auto';
  text.style.width      = 'auto';
  text.style.position   = 'absolute';
  text.style.whiteSpace = 'no-wrap';
  text.innerHTML        = text_a;

  let width = Math.ceil(text.clientWidth);
  document.body.removeChild(text);

  setWidth(width);
}

