import React, { useRef } from 'react'

import Notification from './Notification'
import { useNotifications } from './hook'
import './styles'

export default function IconDropDown() {
  const {
    notifications,
    markAllRead,
    hasMore,
    onNotificationClick,
    populateNotifications,
    unreadCount,
    page
  } = useNotifications();

  const notificationsBodyRef = useRef();

  return (
    <div className='align-items-center d-flex notifications-wrap'>
      <button type="button" className="btn indicator btn-disabled-focus" data-toggle="modal" data-target="#notificationsModal">
        <div className="indicator-icon-wrap">
          <img src='/images/bell_icon.png'/>
          {(unreadCount && unreadCount > 0) ? <div className="right-indicator">{unreadCount}</div> : undefined}
        </div>
      </button>

      <div className="modal fade right modal-standard" id="notificationsModal" tabIndex="-1" role="dialog" aria-labelledby="notificationsModal" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex align-items-center ">
              <h5 className="modal-title flex-1">Notifications</h5>
              <button type="button"
                      disabled={!unreadCount || unreadCount <= 0}
                      className="btn bg-transparent border-0 btn-disabled-focus mark-all-read-btn"
                      onClick={markAllRead}>
                Mark all read
              </button>
              <button type="button" className="close-btn btn btn-disabled-focus ml-3 d-flex" data-dismiss="modal" aria-label="Close">
                <img src='/images/cross_icon.png'/>
              </button>
            </div>
            <div className="modal-body" ref={notificationsBodyRef}>
              {notifications && notifications.map((notification, index) => {
                return (
                  <Notification key={index} onNotificationClick={onNotificationClick} {...notification} />
                );
              })}

              {hasMore &&
                <div className="align-items-center d-flex justify-content-center">
                  <button type="button"
                          className="btn btn-disabled-focus btn-outline-primary btn-load-more my-2"
                          onClick={() => populateNotifications(page + 1)}>
                    Load More
                  </button>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
