import { format } from 'timeago.js'

export const formatDate = (date) => {
  let current_date = new Date();
  let timeDiff = Math.abs(current_date.getTime() - date.getTime());
  let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return (diffDays > 2 ? dateToYMD(date) :format(date))
}

function dateToYMD(date) {
  let strArray=[
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  let d = date.getDate();
  let m = strArray[date.getMonth()];
  let y = date.getFullYear();

  return `${m} ${d} ${(new Date().getFullYear()) !== y ? y : ''}`
}
