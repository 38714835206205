import React, { useEffect, useState } from 'react'
import Toggle from 'react-toggle'
import "react-toggle/style.css"

import NumberInput from './NumberInput'
import CategoriesInput from './CategoriesInput'
import PageHeading from '../PageHeading'

export default React.memo(function ExtraData({
  extractedRows, specialCategory,
  taxIncluded, setTaxIncluded,
  totalTaxAmount, setTotalTaxAmount,
  upgradesAmount, setUpgradesAmount,
  discountAmount, setDiscountAmount,
  permitFee, setPermitFee,
  replacementCost, setReplacementCost,
  notes, setNotes,
  opIncluded, setOpIncluded,
  categoriesOpInclusionMap, setcategoriesOpInclusionMap,
}) {
  const [categoriesInUse, setCategoriesInUse] = useState([]);

  useEffect(() => {
    let tmpMap = {}
    extractedRows.forEach((row) => {
      if (row.willBeIncluded) tmpMap[row.category] = true;
    });
    tmpMap[specialCategory[0]] = true;
    setCategoriesInUse(Object.keys(tmpMap));
  }, [extractedRows, specialCategory]);

  return (
    <div className='extra-data-wrap'>
      <PageHeading
        heading='Additional data'
        helpText='Input additional Data required to prepare final report.'
      />

      <div className='extra-data-content-wrap inner-content-wrap mb-5'>
        <div className='d-flex flex-column'>
          <h3 className='color-primary font-26 font-weight-normal mb-0'>
            Choose data that you want to be included in your final Report
          </h3>

          <div className='inner-section tax-section'>
            <div className='align-items-end d-flex mb-3'>
              <h5 className='color-primary mr-3 mb-0'>Tax Included</h5>
              <Toggle
                id='tax-included'
                defaultChecked={taxIncluded}
                icons={false}
                onChange={() => setTaxIncluded(!taxIncluded)} />
            </div>
            {!taxIncluded &&
              <>
                <label className="string optional">Total Tax Amount</label>
                <NumberInput
                  value={totalTaxAmount}
                  placeholder='Total Tax Amount'
                  setValue={setTotalTaxAmount} />
              </>}
          </div>

          <div className='inner-section op-section'>
            <div className='align-items-end d-flex mb-3'>
              <h5 className='color-primary mr-3 mb-0'>O&P included in paperwork for all categories?</h5>
              <Toggle
                id='tax-included'
                defaultChecked={opIncluded}
                icons={false}
                onChange={() => setOpIncluded(!opIncluded)} />
            </div>
            {!opIncluded &&
              <div className='mt-4'>
                <div className='align-items-end d-flex mb-3'>
                  <h6 className='color-primary mr-3 mb-0'>
                    O&P included in line items? Answer for each category
                  </h6>
                </div>
                <CategoriesInput
                  categories={categoriesInUse}
                  categoriesOpInclusionMap={categoriesOpInclusionMap}
                  setcategoriesOpInclusionMap={setcategoriesOpInclusionMap}
                />
              </div>}
          </div>

          <div className='inner-section extra-fields-section'>
            <div className='fields-group'>
              <div className='elem-with-right-margin'>
                <label className="string optional">Customer Upgrade Amount</label>
                <NumberInput
                  value={upgradesAmount}
                  placeholder='Customer Upgrade Amount'
                  setValue={setUpgradesAmount} />
              </div>
              <div>
                <label className="string optional">Customer Discount Amount</label>
                <NumberInput
                  value={discountAmount}
                  placeholder='Customer Discount Amount'
                  positive
                  setValue={setDiscountAmount} />
              </div>
            </div>
            <div className='fields-group'>
              <div className='elem-with-right-margin'>
                <label className="string optional">Permit Fee</label>
                <NumberInput
                  value={permitFee}
                  placeholder='Permit Fee Amount'
                  setValue={setPermitFee} />
              </div>
              <div>
                <label className="string optional">Replacement Cost Value(without permit fee)</label>
                <NumberInput
                  value={replacementCost}
                  placeholder='Replacement Cost Value(without permit fee)'
                  setValue={setReplacementCost} />
              </div>
            </div>

            <div className='d-flex flex-column'>
              <label className="string optional">Notes</label>
              <textarea
                className='form-control string optional'
                rows='5'
                type="text"
                value={notes}
                placeholder='Any additional notes you want to save'
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
})
