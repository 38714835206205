export const colors = [
  ['#DF7800', '#FFE6BF'],
  ['#00AADF', '#EAF0F6'],
  ['#907CFF']
]

export const colorForLetter = (letter) => {
  return colors[letter.charCodeAt(0) % 2];
}

export const primaryUserColor        = colors[1][0]
export const secondaryUserColor      = colors[0][0]
export const pendingUsercolor        = colors[2][0]
export const primaryUserColorLight   = colors[1][1]
export const secondaryUserColorLight = colors[0][1]
