import consumer from "./consumer"

export const suscribeToRowsExtraction = (summary_id, callback) => {
  // TODO: Update pattern as per notifications_channel.js

  // This subscription and timeout is here for some edge cases relating fast page changes etc
  // That was causing no connection meaning no data being populated in the view

  unsubscribeFromRowsExtraction(summary_id);
  return setTimeout(() => {
    consumer.subscriptions.create({ channel: "RowsExtractionProgressChannel", summary_id: summary_id }, {
      connected() {
        console.log('RowsExtraction channel connected')
      },

      disconnected() {
        console.log('RowsExtraction channel dis-connected')
      },

      unsubscribed() {
        console.log('RowsExtraction channel unsubscribed')
      },

      received(data) {
        callback(data);
      }
    })
  }, 50);
}

export const unsubscribeFromRowsExtraction = (summary_id) => {
  consumer.subscriptions.findAll(JSON.stringify({
    channel: "RowsExtractionProgressChannel", summary_id: summary_id
  })).forEach((subscription) => subscription.unsubscribe());
}
