import { useState, useCallback, useEffect } from 'react'

import { getEditors } from './../services/summaryEditorsService'

export const useEditors = () => {
  const [summaryEditors, setSummaryEditors] = useState([]);
  const [activeSummaryEditors, setActiveSummaryEditors] = useState([]);
  const [summaryIdInternal, setSummaryIdInternal] = useState();
  const [editorsColorMap, setEditorsColorMap] = useState({});

  const [currentUser, setCurrentUser] = useState();
  const [adminAccess, setAdminAccess] = useState(false);

  const populateSummaryEditors = useCallback(() => {
    if (!summaryIdInternal) return

    getEditors(summaryIdInternal).then((resp) => {
      setSummaryEditors(resp.data.editors);
      setCurrentUser(resp.data.current_user);
      setAdminAccess(resp.data.admin_access);
    }).catch((e) => {
      console.error(e);
    });
  }, [summaryIdInternal]);

  useEffect(() => {
    populateSummaryEditors();
  }, [populateSummaryEditors]);

  useEffect(() => {
    let editorsCreatedAtInts =[];
    setActiveSummaryEditors(summaryEditors.reduce((editors, editor) => {
      if (editor.user) {
        editors.push(editor);
        editorsCreatedAtInts.push(editor.created_at_i);
      }
      return editors;
    }, []));

    let earliestCreatedAt = Math.min(...editorsCreatedAtInts);

    setEditorsColorMap(summaryEditors.reduce((map, editor) => {
      map[editor.id] = editor.created_at_i === earliestCreatedAt
      return map;
    }, {}));
  }, [summaryEditors]);

  return {
    populateSummaryEditors,
    summaryEditors,
    currentUser,
    adminAccess,
    activeSummaryEditors,
    setSummaryIdInternal,
    editorsColorMap,
    currentUserId: currentUser ? currentUser.id : undefined
  }
}
