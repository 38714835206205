import React from 'react'

export default React.memo(function CategorySelector({ onChange, category, categories }) {
  return (
    <select className='form-control select optional'
            onChange={onChange}
            onClick={(e) => e.stopPropagation()}
            value={category || ''}>
      <option hidden disabled value=''></option>
      {categories && Object.keys(categories).map((category, index) => {
        return <option key={index} value={category}>{category}</option>
      })}
    </select>
  );
})
