import { useState, useCallback, useEffect } from 'react'
import { formatDate } from '../../helpers/timehelper'

import {
  getNotifications, readNotification, readAllNotifications
} from './../../services/notificationsService'

import { suscribeToNewNotifications } from './../../channels/notifications_channel'

export const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [processing, setProcessing]       = useState(true);
  const [unreadCount, setUnreadCount]     = useState(0);
  const [page, setPage]                   = useState(1);
  const [totalPages, setTotalPages]       = useState(1);

  const parseNotificationsData = useCallback((notifications) => {
    return notifications.map((notification) => {
      notification.created_at = new Date(notification.created_at)
      notification.display_time = formatDate(notification.created_at)

      return notification;
    })
  }, []);


  const populateNotifications = useCallback((pageToLoad) => {
    setProcessing(true);
    setPage(pageToLoad);

    getNotifications(pageToLoad).then((resp) => {
      let objs = parseNotificationsData(resp.data.notifications);
      let perPage = resp.data.per_page;
      setNotifications(notifications.slice(0, perPage * (pageToLoad - 1)).concat(objs));
      setTotalPages(resp.data.total_pages);
      setUnreadCount(resp.data.unread_count);
    }).catch((e) => {
      console.error(e);
      alert('There was an error retrieving notifications');
    }).finally(() => setProcessing(false))
  }, [parseNotificationsData, notifications]);

  useEffect(() => {
    populateNotifications(1);
  }, []);


  const notificationListener = useCallback((data) => {
    notifications.unshift(parseNotificationsData([data.content])[0]);
    setNotifications([...notifications]);
    setUnreadCount(unreadCount + 1);
  }, [notifications, parseNotificationsData, setNotifications, unreadCount]);

  useEffect(() => {
    suscribeToNewNotifications(notificationListener)
  }, [notificationListener]);


  const markAllRead = useCallback(() => {
    if (processing) return;

    if (window.confirm('Mark all notifications read?')) {
      setProcessing(true);
      readAllNotifications().then(() => {
        setNotifications((oldNotifications) => {
          let updatedNotifications = oldNotifications.map((notification) => {
            return { ...notification, read: true }
          });
          setUnreadCount(0);
          return [...updatedNotifications]
        });
      }).catch((e) => {
        console.error(e);
      }).finally(() => setProcessing(false))
    }
  }, [processing]);

  const onNotificationClick = useCallback((notificationId, source_summary_id) => {
    if (processing) return;

    setProcessing(true);
    readNotification(notificationId).then(() => {
      window.location.href = `/summaries/${source_summary_id}`
    }).catch((e) => {
      console.error(e);
    }).finally(() => setProcessing(false))
  }, [processing]);

  return {
    notifications,
    processing,
    markAllRead,
    populateNotifications,
    onNotificationClick,
    page,
    unreadCount,
    hasMore: totalPages > page
  }
}
