import React from 'react'

export default React.memo(function LineItem({ lineItemNo, lineItemAmount }) {
  return (
    <React.Fragment>
      <td>
        {lineItemNo}
      </td>
      <td>
        {lineItemAmount}
      </td>
    </React.Fragment>
  );
})
