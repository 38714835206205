import React, { useCallback, useState } from 'react'
import Avatar from './../../Avatar'

import InviteInput from './InviteInput'

export default React.memo(function Editor({
  editor, currentUserId, setReplaceInputShowing, submitReplaceEditor, submitting, adminAccess, onDestroy, primary
}) {
  const [enableReplacer, setEnableReplacer] = useState(false);

  const showReplaceInput = useCallback(() => {
    if (submitting) return;

    setEnableReplacer(true);
    setReplaceInputShowing(true);
  }, [setReplaceInputShowing, submitting]);

  const hideReplaceInput = useCallback(() => {
    if (submitting) return;

    setEnableReplacer(false);
    setReplaceInputShowing(false);
  }, [setReplaceInputShowing, submitting]);

  const renderWarning = (userName) => {
    return (
      <p className='p-2 warning-text d-flex align-items-center'>
        <img className='pl-1 pr-2' src='/images/warning.png'/>
        {userName} will be removed from file, once the user he invited accepts the invitation.
      </p>
    );
  }

  const renderEditorEntry = (user, isPending, hasPendingReplacementInvite) => {
    return (
      <div className="d-flex flex-row mb-2 pt-2 editor-wrap align-items-center">
        <div className='pr-3'>
          <Avatar
            primaryColor={!isPending && primary}
            pendingUsercolor={isPending}
            value={user.name[0]}
            size="40"
            round="20px" />
        </div>
        <div className="d-flex flex-column flex-1">
          <p className="m-0 dark-text font-weight-light-bold font-18 editor-name">{user.name}</p>
          <p className="m-0 dark-text-opacity font-14 editor-email">{user.email}</p>
        </div>

        <div className='font-14 dark-text-opacity'>
          {(user.id === currentUserId || adminAccess) &&
            <div>
              <button className="btn p-0 dropdown-toggle font-14 dark-text-opacity font-weight-normal btn-disabled-focus"
                      type="button"
                      id="editorActionDropdownMenu"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {isPending ? 'Invitation Pending' : 'Editor'}
              </button>
              <div className="dropdown-menu" aria-labelledby="editorActionDropdownMenu">
                { hasPendingReplacementInvite
                  ? <div className="dropdown-item">Pending replacement invitation</div>
                  : <div onClick={showReplaceInput} className="dropdown-item">Replace</div>
                }
                {adminAccess &&
                  <div onClick={() => {
                    onDestroy && window.confirm('Are you sure you want to remove this editor?') && onDestroy(editor.id, isPending)
                  }} className="dropdown-item">Remove</div>}
              </div>
            </div>
          }
          {!adminAccess && isPending && 'Invitation Pending'}
          {!adminAccess && user.id !== currentUserId && !isPending && 'Editor'}
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      {editor.user && renderEditorEntry(editor.user, false, !!editor.invited_user)}
      {editor.user && editor.invited_user && renderWarning(editor.user.name)}
      {editor.invited_user && renderEditorEntry(editor.invited_user, true)}
      {editor.user && (editor.user.id === currentUserId || adminAccess) && enableReplacer &&
        <div className='d-flex align-items-center'>
          <InviteInput placeholder='email of other user to replace yourself'
                       disabled={submitting}
                       onSubmit={(email) => submitReplaceEditor(editor.id, email, hideReplaceInput)} />
          <p onClick={hideReplaceInput} className='m-0 pl-1 cursor-pointer'>🞪</p>
        </div>
      }
    </React.Fragment>
  )
})
