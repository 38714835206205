import React from 'react'

export default React.memo(function PageHeading(props) {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
      <h2 className='color-primary mb-3 mt-4 font-weight-normal'>
        {props.heading}
      </h2>
      <p className='color-secondary mb-4 text-center' dangerouslySetInnerHTML={{ __html: props.helpText }}/>
      {props.children}
    </div>
  );
})
