import { useState, useCallback } from 'react'

export const useAdditionalSummaryData = () => {
  const [taxIncluded, setTaxIncluded] = useState();
  const [totalTaxAmount, setTotalTaxAmount] = useState();
  const [upgradesAmount, setUpgradesAmount] = useState();
  const [discountAmount, setDiscountAmount] = useState();
  const [permitFee, setPermitFee] = useState();
  const [replacementCost, setReplacementCost] = useState();

  const [opIncluded, setOpIncluded] = useState();
  const [categoriesOpInclusionMap, setcategoriesOpInclusionMapActual] = useState({});
  const [notes, setNotes] = useState('');
  const [warningNote, setWarningNote] = useState('');

  const setcategoriesOpInclusionMap = useCallback((obj) => {
    setcategoriesOpInclusionMapActual({ ...obj })
  }, []);

  const setAdditionalData = useCallback((data) => {
    setTaxIncluded(data.tax_included);
    setTotalTaxAmount(data.total_tax_amount || '');
    setUpgradesAmount(data.upgrades_amount || '');
    setDiscountAmount(data.discount_amount || '');
    setPermitFee(data.permit_fee || '');
    setReplacementCost(data.replacement_cost_value || '');
    setOpIncluded(data.op_included);
    setcategoriesOpInclusionMapActual(data.categories_op_inclusion_map);
    setNotes(data.notes || '');
    setWarningNote(data.warning_note)
  }, []);

  const getAdditionalData = useCallback(() => {
    return {
      tax_included: taxIncluded,
      total_tax_amount: totalTaxAmount,
      upgrades_amount: upgradesAmount,
      discount_amount: discountAmount,
      permit_fee: permitFee,
      replacement_cost_value: replacementCost,
      op_included: opIncluded,
      categories_op_inclusion_map: categoriesOpInclusionMap,
      notes: notes,
    }
  }, [
    taxIncluded,
    totalTaxAmount,
    upgradesAmount,
    discountAmount,
    permitFee,
    replacementCost,
    notes,
    opIncluded,
    categoriesOpInclusionMap,
  ]);

  return {
    taxIncluded, setTaxIncluded,
    totalTaxAmount, setTotalTaxAmount,
    upgradesAmount, setUpgradesAmount,
    discountAmount, setDiscountAmount,
    permitFee, setPermitFee,
    replacementCost, setReplacementCost,
    opIncluded, setOpIncluded,
    categoriesOpInclusionMap, setcategoriesOpInclusionMap,
    notes, setNotes, warningNote,
    setAdditionalData, getAdditionalData,
  }
}
