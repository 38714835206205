import React from 'react'

import LineItem from './LineItem'
import PageHeading from '../PageHeading'

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const numberToCurrency = (number) => formatter.format((number || 0).toFixed(2))

export default React.memo(function Report({
  extractedRows, categories, specialCategory,
  taxIncluded, totalTaxAmount,
  upgradesAmount, discountAmount, permitFee, replacementCost, notes, warningNote,
  opIncluded, categoriesOpInclusionMap,
  skipHeader
}) {
  const parsedUpgradesAmount = parseFloat(upgradesAmount || 0.0) || 0.0;
  const parsedDiscountAmount = parseFloat(discountAmount || 0.0) || 0.0;
  const parsedPermitFee = parseFloat(permitFee || 0.0) || 0.0;
  const parsedReplacementCost = parseFloat(replacementCost || 0.0) || 0.0;

  let categoriesStrIds = {}
  Object.keys(categories).forEach((categoryName) => categoriesStrIds[categoryName] = String(categories[categoryName]))

  const specialCategoryId = String(specialCategory[1]);
  const specialCategoryName = specialCategory[0];

  let categoriesIncluded = {}
  categoriesIncluded[specialCategoryName] = specialCategoryId;
  let categoryRowsMap = {}
  let categoryTotalsMap = {}
  let categoryTaxesMap = {}
  let categoryOPsMap = {}
  let categoryRCVMap = {}
  let maxItemsInSingleCategroy = 0;
  let rcvOfTradesCompleted = 0;
  extractedRows.forEach((row) => {
    if (!row.willBeIncluded) return;

    const categoryName = row.category;
    categoryRowsMap[categoryName] = categoryRowsMap[categoryName] || [];
    categoryRowsMap[categoryName].push(row);
    categoriesIncluded[row.category] = categoriesStrIds[row.category];

    categoryTotalsMap[categoryName] = (categoryTotalsMap[categoryName] || 0) + (row.extracted_line_item_amount || 0);

    if (categoryRowsMap[categoryName].length > maxItemsInSingleCategroy) {
      maxItemsInSingleCategroy = categoryRowsMap[categoryName].length;
    }
  });

  const uncategorizedExist = categoryRowsMap['null'] && categoryRowsMap['null'].length > 0

  // // Calculate grand total of items(raw)
  // const grandTotalsRaw = Object.values(categoryTotalsMap).reduce((sum, subTotal) => {
  //   return sum + subTotal;
  // }, 0);

  // Calculate and store taxes, O&P and RCVs per category
  Object.keys(categoryTotalsMap).forEach((categoryName) => {
    if (!taxIncluded) {
      categoryTaxesMap[categoryName] = calculateTax(categoryTotalsMap[categoryName], parsedReplacementCost, totalTaxAmount);
    }
    if (!opIncluded && !categoriesOpInclusionMap[categoryName]) {
      categoryOPsMap[categoryName] = calculateOPforCategory(categoryTotalsMap[categoryName]);
    }

    categoryRCVMap[categoryName] = categoryTotalsMap[categoryName]
                                 + (categoryTaxesMap[categoryName] || 0)
                                 + (categoryOPsMap[categoryName] || 0);

    if (categoryName !== 'null' && categoryName !== specialCategoryName) {
      rcvOfTradesCompleted += categoryRCVMap[categoryName];
    }
  });

  const grandTotal = rcvOfTradesCompleted + parsedUpgradesAmount + parsedPermitFee - parsedDiscountAmount
  const rcvOfTradesNotDone = (categoryRCVMap[specialCategoryName] || 0) + (categoryRCVMap['null'] || 0)

  // console.log(categoryRowsMap);
  // console.log(maxItemsInSingleCategroy);
  // console.log(categoryTotalsMap);
  // console.log(categoryTaxesMap);
  // console.log(categoryOPsMap);
  // console.log(categoriesOpInclusionMap);
  // console.log(categoryRCVMap);
  // console.log(notes);

  const balanceDifference = parsedReplacementCost - (rcvOfTradesCompleted + rcvOfTradesNotDone);
  const balanced = balanceDifference <= 0.5 && balanceDifference >= -0.5
  // console.log(replacementCost || 0, rcvOfTradesCompleted, rcvOfTradesNotDone, balanceDifference, balanced);

  const categoryNames = Object.keys(categoriesIncluded);
  const categoryNamesWOSpecial = categoryNames.filter((e) => e && e !== 'null' && e !==  specialCategoryName);

  const renderItemRow = (categoryName, i, key) => {
    const data = (categoryRowsMap[categoryName] && categoryRowsMap[categoryName].length >= i)
                 ? categoryRowsMap[categoryName][i]
                 : null;

    return (
      <LineItem
        lineItemNo={data ? data.extracted_line_item_no : ''}
        lineItemAmount={data ? formatter.format(data.extracted_line_item_amount) : ''}
        key={key}
      />
    )
  }

  const renderItemsRows = () => {
    let rows = []

    for (let i = 0; i < maxItemsInSingleCategroy; i++) {
      let children = []
      categoryNamesWOSpecial.forEach((categoryName, j) => {
        children.push(renderItemRow(categoryName, i, j));
      });
      children.push(renderItemRow(specialCategoryName, i, categoryNamesWOSpecial.length + 1));
      uncategorizedExist && children.push(renderItemRow('null', i, categoryNamesWOSpecial.length + 2));

      rows.push(<tr key={i}>{children}<td></td></tr>)
    }
    return rows
  }

  const renderTotalsRow = () => {
    const totalTd = <td className='secondary-heading'>Total</td>
    return (
      <tr>
        {categoryNamesWOSpecial.map((categoryName) => {
          return (
            <React.Fragment key={categoryName}>
              {totalTd}
              <td>{numberToCurrency(categoryTotalsMap[categoryName])}</td>
            </React.Fragment>
          );
        })}
        {totalTd}
        <td className=''>{numberToCurrency(categoryTotalsMap[specialCategoryName])}</td>
        {uncategorizedExist &&
          <>
            {totalTd}
            <td className=''>{numberToCurrency(categoryTotalsMap['null'])}</td>
          </>}
      <td></td></tr>
    )
  }

  const renderTaxesRow = () => {
    const taxTd = <td className='secondary-heading'>Tax</td>
    return (
      <tr>
        {categoryNamesWOSpecial.map((categoryName) => {
          return (
            <React.Fragment key={categoryName}>
              {taxTd}
              <td>{taxIncluded ? 'Included' : numberToCurrency(categoryTaxesMap[categoryName])}</td>
            </React.Fragment>
          );
        })}
        {taxTd}
        <td>{taxIncluded ? 'Included' : numberToCurrency(categoryTaxesMap[specialCategoryName])}</td>
        {uncategorizedExist &&
          <>
            {taxTd}
            <td>{taxIncluded ? 'Included' : numberToCurrency(categoryTaxesMap['null'])}</td>
          </>}
      <td></td></tr>
    )
  }

  const renderOPsRow = () => {
    const opTd = <td className='secondary-heading'>O&P</td>
    return (
      <tr>
        {categoryNamesWOSpecial.map((categoryName)  => {
          return (
            <React.Fragment key={categoryName}>
              {opTd}
              <td>
                {opIncluded || categoriesOpInclusionMap[categoryName]
                  ? 'Included'
                  : numberToCurrency(categoryOPsMap[categoryName])}
              </td>
            </React.Fragment>
          );
        })}
        {opTd}
        <td>
          {opIncluded || categoriesOpInclusionMap[specialCategoryName]
            ? 'Included'
            : numberToCurrency(categoryOPsMap[specialCategoryName])}
        </td>
        {uncategorizedExist &&
          <>
            {opTd}
            <td>
              {opIncluded || categoriesOpInclusionMap['null']
                ? 'Included'
                : numberToCurrency(categoryOPsMap['null'])}
            </td>
          </>}
      <td></td></tr>
    )
  }

  const renderRCVsRow = () => {
    const rcvTd = <td className='secondary-heading'>RCV</td>
    return (
      <tr>
        {categoryNamesWOSpecial.map((categoryName)  => {
          return (
            <React.Fragment key={categoryName}>
              {rcvTd}
              <td className='secondary-heading'>{numberToCurrency(categoryRCVMap[categoryName])}</td>
            </React.Fragment>
          );
        })}
        {rcvTd}
        <td className='secondary-heading'>{numberToCurrency(categoryRCVMap[specialCategoryName])}</td>
        {uncategorizedExist &&
          <>
            {rcvTd}
            <td className='secondary-heading'>{numberToCurrency(categoryRCVMap['null'])}</td>
          </>}
      <td></td></tr>
    )
  }

  const renderRepeatedHeadersRow = () => {
    return (
      <tr>
        {categoryNamesWOSpecial.map((categoryName) => {
          return (
            <td className='text-center th-style' key={categoryName} colSpan={2}>{categoryName}</td>
          );
        })}
        <td className='text-center th-style' key={specialCategoryId} colSpan={2}>{specialCategoryName}</td>
        {uncategorizedExist && <td className='text-center th-style' colSpan={2}>{'(Uncategorized)'}</td>}
      <td></td></tr>
    );
  }

  const renderSecondaryHeadings = () => {
    return (
      <tr>
        {categoryNames.map((_, i) => (
          <React.Fragment key={i}>
            <td className='secondary-heading'>#</td>
            <td className='secondary-heading'>Amount</td>
          </React.Fragment>
        ))}
      <td></td></tr>
    )
  }

  const doneByUsCategoryColsCount = (categoryNamesWOSpecial.length) * 2;

  return (
    <div className='d-flex flex-column report-wrap'>
      {!skipHeader &&
        <PageHeading
          heading='Review the final report before saving'
          helpText='This report is based on items approved by all editors.'
        />}
      <div className='d-flex flex-column inner-content-wrap w-fit-content'>
        <div className='table-wrap d-flex flex-column overflow-auto bg-white w-fit-content'>
          <table className="table summary-report-table">
            <colgroup>
              {doneByUsCategoryColsCount > 0 && <col span={doneByUsCategoryColsCount}/>}
              <col span={`${uncategorizedExist ? 4 : 2}`} className='bg-special' />
            </colgroup>
            <thead>
              <tr>
                {categoryNamesWOSpecial.map((categoryName) => {
                  return (
                    <th className='text-center' key={categoryName} colSpan={2}>{categoryName}</th>
                  );
                })}
                <th className='text-center' key={specialCategoryId} colSpan={2}>{specialCategoryName}</th>
                {uncategorizedExist && <th className='text-center' colSpan={2}>{'(Uncategorized)'}</th>}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {renderSecondaryHeadings()}
              {renderItemsRows()}
              {renderRepeatedHeadersRow()}
              {renderTotalsRow()}
              {renderTaxesRow()}
              {renderOPsRow()}
              {renderRCVsRow()}
            </tbody>
          </table>
        </div>
        <span className={`badge badge-success mt-0 badge-info-special`}>
          {warningNote}
        </span>
        <span className={`badge badge-success mt-0 custom-${balanced ? 'success' : 'error'}-badge`}>
          {balanced
            ? 'Balanced'
            : `Not Balanced (Replacement Cost - Your RCV = ${formatter.format(balanceDifference)})`}
        </span>

        <div className='bottom-wrap d-flex flex-column color-primary'>
          <div className='d-flex mb-4'>
            <h5 className='info-title mr-3 mb-0'>Total RCV of Trades Completed</h5>
            <h5 className='mr-3 mb-0'>{formatter.format(rcvOfTradesCompleted)}</h5>
          </div>
          <div className='d-flex smaller-h5-wrap'>
            <h5 className='info-title mr-3 mb-0'>Upgrades</h5>
            <h5 className='mr-3 mb-0'>{formatter.format(parsedUpgradesAmount)}</h5>
          </div>
          <div className='d-flex smaller-h5-wrap'>
            <h5 className='info-title mr-3 mb-0'>Discounts</h5>
            <h5 className='mr-3 mb-0'>{formatter.format(parsedDiscountAmount)}</h5>
          </div>
          <div className='d-flex smaller-h5-wrap'>
            <h5 className='info-title mr-3 mb-0'>Permit Fee</h5>
            <h5 className='mr-3 mb-0'>{formatter.format(parsedPermitFee)}</h5>
          </div>
          <div className='d-flex'>
            <h4 className='info-title mr-3 mb-0'>Grand Total</h4>
            <h4 className='mr-3 mb-0'>{formatter.format(grandTotal)}</h4>
          </div>
          <div className='d-flex smaller-h5-wrap'>
            <h5 className='info-title mr-3 mb-0'>Replacement Cost Value(without permit fee)</h5>
            <h5 className='mr-3 mb-0'>{formatter.format(replacementCost || 0)}</h5>
          </div>
          <div className='d-flex notes-wrap'>
            <p className='color-light-secondary font-weight-light-bold mr-2 mb-0 font-18'>Notes:</p>
            <p className='color-light-secondary mb-0 white-space-pre-wrap'>{notes}</p>
          </div>
        </div>
      </div>
    </div>
  );
})

// ((all completed work/RCV Value)*total taxes)
function calculateTax(categoryTotal, parsedReplacementCost, totalTaxAmount) {
  // return (totalTaxAmount / grandTotalsRaw) * categoryTotal;
  return parsedReplacementCost === 0
         ? 0
         : (categoryTotal / parsedReplacementCost) * totalTaxAmount
}

function calculateOPforCategory(categoryTotal) {
  return (2.0 / 10.0) * categoryTotal;
}
