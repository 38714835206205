import React, { useState, useEffect } from 'react'

import Report from './index'
import { parseCsvRows } from '../actions'

import { useAdditionalSummaryData } from './../../../hooks/additionalSummaryDataHook';

export default React.memo(function StandAloneReportWrap({ data }) {
  const [extractedRows, setExtractedRows] = useState();
  const [categories, setCategories] = useState([]);
  const [specialCategory, setSpecialCategory] = useState();

  const additionalSummaryDataHookData = useAdditionalSummaryData();
  const { setAdditionalData } = additionalSummaryDataHookData;

  useEffect(() => {
    setCategories(data.categories);
    setSpecialCategory(data.special_category);
    setAdditionalData(data);

    setExtractedRows(parseCsvRows(data.rows));
  }, [data, setAdditionalData]);

  if(!extractedRows) return null;

  return (
    <Report
      categories={categories}
      specialCategory={specialCategory}
      extractedRows={extractedRows}
      skipHeader={true}
      {...additionalSummaryDataHookData}
    />
  );
})
