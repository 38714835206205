// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';
// eslint-disable-next-line no-undef
global.$ = jQuery;

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import '../src/application.scss';
import 'react-dropzone-uploader/dist/styles.css';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap';

document.addEventListener('turbolinks:load', function() {
  $('#deleteModal').on('show.bs.modal', (e) => {
      let data = $(e.relatedTarget).data('http');
      let elem = $(e.currentTarget).find('a#actionBtn');
      elem.attr('href', data.url);
      elem.attr('data-method', data.method);
  });

  $('.remote-link-overlay').bind('ajax:beforeSend', function() {
    $('#overlay').show();
  });

  $('.remote-link-overlay').bind('ajax:complete', function() {
    setTimeout(() => $('#overlay').hide(), 150);
  });
})

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
