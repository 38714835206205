import React, { useCallback, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { createEditor, updateEditor, removeEditor } from './../../../services/summaryEditorsService'

import Editor from './Editor'
import InviteInput from './InviteInput'

export default React.memo(function ShareModal({
  summaryId,
  populateSummaryEditors,
  summaryEditors,
  currentUserId,
  adminAccess,
  editorsColorMap
}) {
  const [replaceInputShowing, setReplaceInputShowing] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const submitCreateEditor = useCallback((email) => {
    setSubmitting(true);
    createEditor(summaryId, email).then(() => {
      populateSummaryEditors();
    }).catch((e) => {
      alert(e.response.data.error);
    }).finally(() => setSubmitting(false));
  }, [summaryId, populateSummaryEditors]);

  const submitReplaceEditor = useCallback((editorId, email, successCallback) => {
    setSubmitting(true);
    updateEditor(summaryId, editorId, email).then(() => {
      populateSummaryEditors();
      successCallback();
    }).catch((e) => {
      alert(e.response.data.error);
    }).finally(() => setSubmitting(false));
  }, [summaryId, populateSummaryEditors]);

  const destroyEditor = useCallback((editorId, destroyInvited) => {
    setSubmitting(true);
    removeEditor(summaryId, editorId, destroyInvited).then(() => {
      populateSummaryEditors();
    }).catch(() => {
      alert('There was an error while deleting editor');
    }).finally(() => setSubmitting(false));
  }, [summaryId, populateSummaryEditors]);

  return (
    <div className="modal modal-standard fade" id="shareSummaryModal">
      <div className="modal-dialog modal-dialog-centered modal-dialog-extended">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="m-0 p-2">Share Summary</h4>
          </div>
          <div className="modal-body d-flex flex-column">

            <div className="editors-wrap flex-1">
              {summaryEditors && summaryEditors.map((editor, index) => {
                return (
                  <Editor
                    key={index}
                    editor={editor}
                    primary={editorsColorMap[editor.id]}
                    adminAccess={adminAccess}
                    onDestroy={destroyEditor}
                    currentUserId={currentUserId}
                    submitReplaceEditor={submitReplaceEditor}
                    submitting={submitting}
                    setReplaceInputShowing={setReplaceInputShowing} />
                );
              })}

              {summaryEditors && summaryEditors.length <= 1 && !replaceInputShowing &&
                <>
                  <p className='m-0 .dark-text font-14 mb-1'>Invite an additional editor to this summary</p>
                  <InviteInput placeholder='email of the editor to invite'
                               disabled={submitting}
                               onSubmit={submitCreateEditor} />
                </>
              }
            </div>

            <div className="d-flex mt-2">
              <button className="btn btn-primary btn-round-edge mr-2" data-dismiss="modal">Done</button>

              <CopyToClipboard text={`${location.origin}/summaries/${summaryId}`}
                onCopy={() => alert("Link copied to your clipboard.")}>
                <button className="btn btn-outline-primary btn-round-edge btn-copy-url">Copy Link</button>
              </CopyToClipboard>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
