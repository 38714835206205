import React from "react"

export default React.memo(React.forwardRef((props, ref) => {
  return (
    <div className="modal modal-standard fade confirm-cancellation-modal" ref={ref}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className='modal-header'>
            <h4>Terminate This Process?</h4>
          </div>
          <div className="modal-body d-flex flex-column">
            <p> Doing this will terminate the process & your progress will be erased. </p>

            <div className='d-flex'>
              <a className='btn btn-primary btn-round-edge mr-2' href='/summaries'>
                Terminate
              </a>
              <button className='btn btn-outline-primary btn-round-edge' data-dismiss="modal"> Cancel </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}))
