import axios from "axios";
import { ContentType } from "./enums";
// import { requestHandler, successResponseHandler, errorResponseHandler } from "./interceptors";

const getAxiosInstance = (config = {
    headers: { contentType: ContentType.json },
  }) => {
    const instance = axios.create({
        baseURL: "/",
        headers: {
          'Content-Type' : config.headers.contentType || ContentType.json,
        },
    });

    // instance.interceptors.request.use(requestHandler);
    // instance.interceptors.response.use(successResponseHandler, errorResponseHandler);

    const tokenDom = document.querySelector("meta[name=csrf-token]")
      if (tokenDom) {
         const csrfToken = tokenDom.content
         instance.defaults.headers.common['X-CSRF-Token'] = csrfToken
      }

    return instance;
}

export default getAxiosInstance();
