import React, { useEffect, useCallback } from 'react'
import Dropzone from 'react-dropzone-uploader'
import { Line } from 'rc-progress'
import SVG from 'react-inlinesvg'

import { textForStatus, formatBytes } from './actions'
import {
  suscribeToRowsExtraction,
  unsubscribeFromRowsExtraction
} from '../../channels/rows_extraction_progress_channel.js'

export default function FileUpload({ processDataCallback, summaryId, onStartUpload, onUploadComplete }) {
  const getUploadParams = useCallback((params) => {
    const body = new FormData();
    body.append('file', params.file);
    body.append('authenticity_token', $('meta[name="csrf-token"]').attr('content'));
    return { url: '/summaries', body }
  }, []);

  const processData = useCallback((data) => {
    processDataCallback(data);
    unsubscribeFromRowsExtraction(summaryId);
  }, [processDataCallback, summaryId]);

  useEffect(() => {
    if (summaryId) suscribeToRowsExtraction(summaryId, processData);
  }, [summaryId, processData]);

  const onChangeStatus = useCallback((fileWithMeta, status) => {
    if (status === 'done') {
      const response = JSON.parse(fileWithMeta.xhr.response);
      onUploadComplete(response);
    }
  }, [onUploadComplete]);

  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
      <h2 className='color-primary mb-3 mt-4 font-weight-normal'>
        Upload New PDF File
      </h2>
      <p className='color-secondary m-0'>
        Upload the PDF File that you want to be processed in this system.
      </p>

       {summaryId ? renderWaitWidget(summaryId) :
          <Dropzone
            disabled={!!summaryId}
            getUploadParams={getUploadParams}
            onChangeStatus={onChangeStatus}
            accept='.pdf'
            autoUpload={false}
            maxFiles={1}
            multiple={false}
            canCancel={true}
            inputContent={renderInputComp}
            PreviewComponent={(params) => renderPreviewComp(params, onStartUpload)}
            styles={{
              dropzone: {
                overflow: 'hidden',
                maxWidth: '630px',
                minHeight: '182px',
                background: '#E3E5EA',
                borderRadius: '4px',
                border: 'none'
              },
              dropzoneActive: { borderColor: 'green' },
            }}
          />
        }
    </div>
  );
}

function renderPreviewComp({ meta, isUpload, fileWithMeta, canRemove, canRestart }, onStartUpload) {
  return (
    <>
      {isUpload && meta.status === 'uploading' && (
        <Line percent={meta.status === 'done' || meta.status === 'headers_received' ? 100 : meta.percent}
              strokeWidth='4' strokeColor='#3CBD77' trailColor='#E3E5EA'
              trailWidth='0'
              strokeLinecap='square'
              className='progress-bar'/>
      )}
      <div className='d-flex uploader-wrap w-100'>
        <img src='/images/upload_icon.svg'/>

        <div className='d-flex flex-column justify-content-center flex-1'>
          <h5>{textForStatus(meta.status)}</h5>
          <div className='d-flex'>
            <SVG className='pdficon'  src='/images/pdf_icon.svg' />
            <div className='d-flex flex-column justify-content-center flex-1'>
              <p className='font-14 mb-1'>{meta.name}</p>
              <p className='font-12'>{formatBytes(meta.size)}</p>
            </div>

            {meta.status !== 'preparing' &&
             meta.status !== 'getting_upload_params' &&
             meta.status !== 'uploading' &&
             canRemove && (
              <span className='mx-5 cross-btn' onClick={fileWithMeta.remove}>
                ×
              </span>
            )}
          </div>
          {['error_upload_params',
            'exception_upload',
            'error_upload',
            'aborted',
            'ready'].includes(meta.status) && canRestart &&
            <button className='btn btn-info btn-round-edge catskill-white-btn'
                    onClick={() => {
                      fileWithMeta.restart();
                      onStartUpload();
                    }}>Start Processing</button>}
          {meta.status === 'error_upload' && <a href='#' onClick={fileWithMeta.remove}>Re-try?</a>}
        </div>
      </div>
    </>
  );
}

function renderInputComp() {
  return (
    <div className='d-flex uploader-wrap'>
      <img src='/images/upload_icon.svg'/>

      <div className='d-flex flex-column justify-content-center'>
        <h5>Drag and drop or upload a file from your computer</h5>
        <a className='btn btn-outline-danger btn-round-edge mt-4 bg-transparent'>
          UPLOAD FILE
        </a>
      </div>
    </div>
  );
}

function renderWaitWidget(summaryId) {
  return (
    <div className='dzu-dropzone dzu-dropzone-copy'>
      <div className='align-items-center d-flex uploader-wrap'>
        <div className='spinner-wrap'>
          <div className='loader-spinner'>Loading...</div>
        </div>
        <div className='d-flex flex-column justify-content-center pr-4'>
          <h5>Your file is being processed...</h5>
          <p>It may take a few minutes. You can come back later & pick where you left.</p>

          <a href={`/summaries?highlight=${summaryId}`}
             className='btn btn-info btn-round-edge catskill-white-btn mt-3'>
            Go Back To Summaries
          </a>
        </div>
      </div>
    </div>
  );
}
