import CSV from 'csv-es';

export const updateExtraRowData = (row) => {
  // Logic to get extracted_line_item_no
  if (!row.extracted_line_item_no) {
    let val = String(row.data[0]).replace(/(^\d+)(.+$)/i,'$1')
    if (val !== '') row.extracted_line_item_no = parseInt(val) || undefined
  }

  // Logic to get extracted_line_item_amount
  // Logic to be synced with parseCsvRows below
  let approvedCRinRow;
  row.change_requests && row.change_requests.some((changeRequest) => {
    if (changeRequest.approved) {
      approvedCRinRow = changeRequest;
      return true;
    }
  });
  row.approvedCRinRow = approvedCRinRow;

  let includedColsToBe = row.included_cols;
  if (approvedCRinRow) {
    includedColsToBe = approvedCRinRow.category === 'remove' ? null : approvedCRinRow.proposed_cells
  }

  let dataToConvert;
  if (includedColsToBe) {
    dataToConvert = row.data[includedColsToBe[1]]
    let extractedNumbers = String(dataToConvert).replace(/[,$]/g, '').match(/\d+\.\d+|\d+\b|\d+(?=\w)/g)
    row.extracted_line_item_amount = !extractedNumbers || extractedNumbers.length === 0 ? null : parseFloat(extractedNumbers[0]);

    row.willBeIncluded = true;
    row.includedColsToBe = includedColsToBe;
  } else {
    row.extracted_line_item_amount = undefined;
    row.willBeIncluded = false;
    row.includedColsToBe = undefined;
  }

  console.log(
    row.willBeIncluded,
    row.includedColsToBe,
    row.extracted_line_item_no,
    row.extracted_line_item_amount,
    row.approvedCRinRow
  );

  return row;
}

export const countSelectedRows = (rows) => {
  return rows.reduce((count, elem) => {
    return (elem.included_cols || (elem.change_requests && elem.change_requests.length > 0)) ? count + 1 : count
  }, 0);
}

export const parseCsvRows = (rows) => {
  let parsed_data = [];
  rows.forEach((row) => {
    try {
      let parsed_row_data = CSV.parse(row.data);
      if (parsed_row_data.length === 0) {
        parsed_row_data = [row.data.replace('"', '')]
      } else {
        parsed_row_data = parsed_row_data[0]
      }
      row.data = parsed_row_data
      row.willBeIncluded = (!!row.included_cols && row.included_cols.length > 0);
      row.includedColsToBe = row.included_cols;
      parsed_data.push(row);
    } catch(err) {
      console.log(row.data);
      console.error(err);
    }
  });

  return parsed_data;
}

export const textForStatus = (status) => {
  switch(status) {
    case 'uploading':
      return 'File being uploaded...'
    case 'error_upload':
      return 'Error occurred'
    case 'ready':
      return 'Ready to process'
    default:
      return 'Upload Complete'
  }
}

export const formatBytes = (b) => {
  const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let l = 0
  let n = b

  while (n >= 1024) {
    n /= 1024
    l += 1
  }

  return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)}${units[l]}`
}
