import React from 'react'

export default React.memo(function TableHeader() {
  return (
    <div className='d-flex flex-row form-group  m-0 row-wrap py-2 th-like'>
      <div className='data-wrap p-2'>
        <div style={{width: '100%', border: 'none'}}>DATA</div>
        <div className='category-column border-0'>CATEGORY</div>
      </div>
    </div>
  );
})
