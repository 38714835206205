import React, { useState, useCallback, useEffect } from "react"

import { parseCsvRows, countSelectedRows } from './actions'

import { finalizeSummary } from './../../services/summariesService.js';

import { useEditors } from './../../hooks/editorsHook'
import { useAdditionalSummaryData } from './../../hooks/additionalSummaryDataHook';

import NavBar from './NavBar'
import Footer from './Footer'
import FileUpload from './FileUpload'
import SelectItems from './Items/Select'
import ExtraData from './ExtraData'
import Report from './Report'
import ShareModal from './ShareModal'

const tabIndexHashnames = [
  '',
  'select-items',
  'review-items',
  'additional-data',
  'review-save'
]

const getTabIndexFromHash = () => {
  if (window.location.hash === `#${tabIndexHashnames[1]}`) {
    return 1;
  } else if (window.location.hash === `#${tabIndexHashnames[2]}`) {
    return 2;
  } else if (window.location.hash === `#${tabIndexHashnames[3]}`) {
    return 3;
  } else if (window.location.hash === `#${tabIndexHashnames[4]}`) {
    return 4;
  } else {
    undefined
  }
}

export default function Form(props) {
  const [tabIndex, setTabIndex]           = useState(getTabIndexFromHash() || (props.data ? 1 : 0));
  const [summaryId, setSummaryId]         = useState(props.summaryId);
  const [categories, setCategories]       = useState([]);
  const [specialCategory, setSpecialCategory] = useState();

  const [selectedCount, setSelectedCount] = useState(0);
  const [uploadStarted, setUploadStarted] = useState(false);

  const [summaryTitle, setSummaryTitle]     = useState();
  const [oldTitle, setOldTitle]             = useState();

  const [extractedRows, setExtractedRows] = useState(props.data ? [] : undefined);

  const editorHookData = useEditors();
  const {
    currentUserId,
    currentUser,
    adminAccess,
    summaryEditors,
    activeSummaryEditors,
    setSummaryIdInternal,
    editorsColorMap
  } = editorHookData;
  // console.log(currentUserId, adminAccess, activeSummaryEditors);

  const additionalSummaryDataHookData = useAdditionalSummaryData();
  const {
    setAdditionalData,
    getAdditionalData
  } = additionalSummaryDataHookData;

  useEffect(() => {
    setSummaryIdInternal(summaryId);
  }, [setSummaryIdInternal, summaryId]);

  const hashChange = useCallback((e) => {
    e.preventDefault();
    setTabIndex(getTabIndexFromHash());
  }, []);

  useEffect(() => {
    if (!window.location.hash) history.replaceState(null, null, `${window.location.pathname}#${tabIndexHashnames[1]}`)
    $(window).unbind('hashchange', hashChange);
    $(window).bind('hashchange', hashChange);

    if (props.data) processDataCallback(props.data);
  }, [hashChange, processDataCallback, props.data]);
  // TODO: these dependencies might not be needed, keep an eye

  useEffect(() => {
    setSelectedCount(countSelectedRows(extractedRows || []));
  }, [extractedRows]);

  const handleBack = useCallback(() => {
    setTabIndex((oldTabIndex) => (oldTabIndex >= 2) ? (oldTabIndex -1) : oldTabIndex);
  }, []);

  // whenever tab index changes, set correct hash
  useEffect(() => {
    switch(tabIndex) {
      case 1:
        window.location.hash = tabIndexHashnames[1];
        break;
      case 2:
        window.location.hash = tabIndexHashnames[2];
        break;
      case 3:
        window.location.hash = tabIndexHashnames[3];
        break;
      case 4:
        window.location.hash = tabIndexHashnames[4];
        break;
    }
  }, [tabIndex]);

  const processDataCallback = useCallback((data) => {
    setCategories(data.content.categories);
    setSpecialCategory(data.content.special_category);
    setAdditionalData(data.content);
    if (data.content.error) {
      alert(`There was an error in processing of the file, ${data.content.error}`);
      location.href = '/summaries'
    } else {
      setExtractedRows(parseCsvRows(data.content.rows));
      setSummaryTitle(data.content.title);
      setOldTitle(data.content.title);

      setTabIndex((oldIndex) => oldIndex < 1 ? 1 : oldIndex);
      // setTabIndex(1);
    }
  }, [setExtractedRows, setSummaryTitle, setOldTitle, setTabIndex, setCategories, setAdditionalData]);

  const submitForm = useCallback((name, selectedCount) => {
    if (!name || !selectedCount) return;

    $('#savebtn').attr('disabled', true);

    // BUILD JSON

    let summaryRowsIdDataMap = {};
    let changeRequestIdsToRemove = [];

    extractedRows.forEach((elem) => {
      if (!elem.changed) return;

      summaryRowsIdDataMap[elem.id] = {
        extracted_line_item_amount: elem.extracted_line_item_amount,
        extracted_line_item_no: elem.extracted_line_item_no,
        category: elem.category,
        included_cols: elem.included_cols,
        change_requests: elem.change_requests ? elem.change_requests.filter((cr) => cr.changed || !cr.id) : []
      };

      if (elem.change_request_ids_to_remove) {
        changeRequestIdsToRemove = changeRequestIdsToRemove.concat(elem.change_request_ids_to_remove)
      }
    });

    finalizeSummary(
      summaryId,
      {
        summary_rows_id_map: summaryRowsIdDataMap,
        title: name,
        change_request_ids_to_remove: changeRequestIdsToRemove,
        ...getAdditionalData(),
      }
    ).then(() => {
      location.reload();
      document.location = `/summaries/${summaryId}`;
    }).catch((e) => {
      console.error(e);
      alert('There was an error, please try again or contact support.');
      $('#savebtn').attr('disabled', false);
    });
  }, [getAdditionalData, extractedRows, summaryId]);

  const handleNext = useCallback(() => {
    setTabIndex((oldTabIndex) => {
      if (oldTabIndex === 4) {
        if(summaryTitle) submitForm(summaryTitle, selectedCount);
        return oldTabIndex;
      } else {
        return oldTabIndex + 1;
      }
    });
  }, [submitForm, summaryTitle, selectedCount]);

  return (
    <>
      <ShareModal summaryId={summaryId} {...editorHookData} />
      <div id='content-wrap'>
        <NavBar tabIndex={tabIndex}
                summaryId={summaryId}
                summaryTitle={summaryTitle}
                editing={!!oldTitle}
                uploadStarted={uploadStarted}
                summaryEditors={summaryEditors}
                editorsColorMap={editorsColorMap}
                currentUserId={currentUserId}
                setSummaryTitle={setSummaryTitle}/>
        <div className='page-wrap'>
          {tabIndex === 0 &&
            <FileUpload
              summaryId={summaryId}
              onStartUpload={() => setUploadStarted(true)}
              processDataCallback={processDataCallback}
              onUploadComplete={(response) => {
                setSummaryId(response.summary.id);
                history.replaceState(null, null, `/summaries/${response.summary.id}#${tabIndexHashnames[1]}`);
              }}
            />}

          {extractedRows && currentUser && (tabIndex === 1 || tabIndex === 2) &&
            <SelectItems
              summaryId={summaryId}
              extractedRows={extractedRows}
              editorsColorMap={editorsColorMap}
              setExtractedRows={setExtractedRows}
              categories={categories}
              tabIndex={tabIndex}
              editing={!!oldTitle}
              currentUser={currentUser}
              specialCategoryName={specialCategory[0]}
              needApprovals={!adminAccess && activeSummaryEditors && activeSummaryEditors.length > 1}
            />
          }
          {extractedRows && currentUser && tabIndex === 3 &&
            <ExtraData
              extractedRows={extractedRows}
              categories={categories}
              specialCategory={specialCategory}
              {...additionalSummaryDataHookData}
            />
          }
          {extractedRows && currentUser && tabIndex === 4 &&
            <Report
              categories={categories}
              specialCategory={specialCategory}
              extractedRows={extractedRows}
              {...additionalSummaryDataHookData}
            />
          }
        </div>
      </div>

      {tabIndex >= 1 &&
        <Footer
          handleNext={handleNext}
          handleBack={handleBack}
          showNext={props.tabIndex !== 0 && !!extractedRows}
          selectedCount={selectedCount}
          tabIndex={tabIndex}
          errorMessage={(tabIndex === 4 && !summaryTitle) ? 'Please add a title to the summary' : (!selectedCount && 'No rows selected')}
        />
      }
    </>
  );
}
