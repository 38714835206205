import React, { useEffect } from 'react'
import ShareModal from './index'
import { useEditors } from './../../../hooks/editorsHook'

export default function WithButton({ summaryId }) {
  const editorHookData = useEditors();

  const { setSummaryIdInternal } = editorHookData;
  // console.log(currentUserId, adminAccess, activeSummaryEditors);

  useEffect(() => {
    setSummaryIdInternal(summaryId);
  }, [setSummaryIdInternal, summaryId]);

  return (
    <React.Fragment>
      <a data-toggle="modal"
         className="btn btn-primary btn-round-edge btn-danger font-weight-normal"
         href="#shareSummaryModal">
        Share
      </a>
      <ShareModal summaryId={summaryId} {...editorHookData} />
    </React.Fragment>
  );
}
