import React, { useState } from 'react'

export default React.memo(function InviteInput({ onSubmit, placeholder, disabled }) {
  const [email, setEmail] = useState('');

  return (
    <div className='d-flex flex-row flex-1'>
      <input className='flex-1 invite-email font-14 px-1' type='email'
             value={email || ''}
             disabled={disabled}
             placeholder={placeholder || 'Email'}
             onChange={(e) => setEmail(e.target.value)} />
      <button className='btn btn-primary invite-button' onClick={() => onSubmit && !disabled && onSubmit(email)} >Send Invite</button>
    </div>
  );
})
