import React, { useCallback } from 'react'

export default React.memo(function InfoData({ type, previousCellData, proposedCellData }) {
  const cellData = useCallback((data) => {
    return <b className='m-0 dark-text font-weight-light-bold'>“{data}”</b>
  }, [])

  return (
    <div>
      { type === 'edit' && <p className='m-0 font-14'>
        Changed cell {cellData(previousCellData)} to cell {cellData(proposedCellData)}.
      </p>}
      { type === 'add' && <p className='m-0 font-14'>
        Added cell {cellData(proposedCellData)}.
      </p>}
      { type === 'remove' && <p className='m-0 font-14'>
        Removed cell {cellData(previousCellData)}.
      </p>}
    </div>
  );
})
